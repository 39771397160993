import { updateOrganizationProfileApi } from './../../CompanyProfile/CompanyDescription/Redux/Api'
import { didUpdateContent } from './../../Edit/Redux/Actions'
import {
  validatePresence,
  emailFormValidator,
  phoneNumberFormValidator,
} from 'src/common/Form/FormValidations'
import { updateProProfileApi } from './Api'
import { updatePro } from 'src/common/CurrentPro/CurrentPro'

export function validateOrganization(values) {
  const errors = {}
  const { phone_number, support_email, name } = values

  errors.phone_number =
    validatePresence(phone_number) || phoneNumberFormValidator(phone_number)
  errors.support_email =
    validatePresence(support_email) || emailFormValidator(support_email)
  errors.name = validatePresence(name)

  return errors
}

export function updateOrganization(organizationData) {
  organizationData.phone_number.replace(/[-() ]/g, '')

  return dispatch => {
    return updateOrganizationProfileApi(organizationData).then(response => {
      dispatch(didUpdateContent('organization', response.data))
    })
  }
}


export function validatePro(values) {
  const errors = {}
  const { first_name, last_name, mobile_number } = values

  errors.mobile_number = validatePresence(mobile_number) || phoneNumberFormValidator(mobile_number)
  errors.first_name = validatePresence(first_name)
  errors.last_name = validatePresence(last_name)

  return errors
}

export function updateProProfile(proData) {
  proData.mobile_number.replace(/[-() ]/g, '')
  return dispatch => {
    return updateProProfileApi(proData).then(response => {
      dispatch(updatePro(response.data))
    })
  }
}
